// src/pages/HomePage.js
import React, {  useState, useEffect, useRef } from 'react';
import ProductList from '../components/product/ProductList';
import './HomePage.css';
import { useProductContext } from '../context/ProductContext';

const HomePage = () => {
  const { products, setProducts, loading, setLoading, error, setError } = useProductContext();
  const [scrollPosition, setScrollPosition] = useState(0); // State to track scroll position

  const mainRef = useRef(null);

  // Effect to save scroll position to local storage
  useEffect(() => {
    localStorage.setItem('scrollPosition', JSON.stringify(scrollPosition));
  }, [scrollPosition]);

  // Effect to restore scroll position from local storage on component mount
  useEffect(() => {
    const savedScrollPosition = JSON.parse(localStorage.getItem('scrollPosition'));
    if (savedScrollPosition !== null && mainRef.current !== null) {
      mainRef.current.scrollTo(0, savedScrollPosition);
    }
  }, []);

  // Effect to fetch data (similar to your previous implementation)
  useEffect(() => {
    if (products === null) { // Only fetch if products are not already loaded
      const fetchData = async () => {
        try {
          await new Promise(resolve => setTimeout(resolve, 1000));
          setProducts([]); // Replace with actual fetched products
          setLoading(false);
        } catch (err) {
          setError('Failed to load products.');
          setLoading(false);
        }
      };

      fetchData();
    } else {
      setLoading(false); // Ensure loading state is false if products are already loaded
    }
  }, [products, setProducts, setLoading, setError]);

  // Event handler to update scroll position state
  const handleScroll = () => {
    if (mainRef.current !== null) {
      setScrollPosition(mainRef.current.scrollTop);
    }
  };

  return (
    <div className="homepage">
      <header className="homepage-header">
        <h1>Welcome to Our Store</h1>
      </header>
      <main className="homepage-content" ref={mainRef} onScroll={handleScroll}>
        {loading && <div className="loading-overlay">Loading...</div> }
        {error && <p className="error">{error}</p>}
        {!loading && !error && (
          <ProductList />
        )}
      </main>
      <footer className="homepage-footer">
        <p>&copy; 2024 Knightcoder</p>
      </footer>
    </div>
  );
};

export default HomePage;